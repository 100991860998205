import React, { Dispatch, SetStateAction } from "react";
import {
  RoleRepresentation,
  UserDoc,
} from "../components/Management/Users/User";
import { GroupBody } from "../components/Management/Groups/Group";

export type AccessManagementContextType = {
  users: {
    value: UserDoc[] | undefined;
    set: (users: UserDoc[] | undefined) => void;
  };
  groups: {
    value?: GroupBody[];
    set: () => void;
  };
  roles: {
    value?: RoleRepresentation[];
    set: () => void;
  };
  snackbar: {
    value:
      | {
          message: string;
          open: boolean;
        }
      | undefined;
    set: (value: { message: string; open: boolean }) => void;
  };
};

const AccessManagementContext =
  React.createContext<AccessManagementContextType>({
    users: {
      value: undefined,
      set: () => {},
    },
    groups: {
      value: undefined,
      set: () => {},
    },
    roles: {
      value: undefined,
      set: () => {},
    },
    snackbar: {
      value: undefined,
      set: () => {},
    },
  });

export default AccessManagementContext;
