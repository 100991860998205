import { Reducer } from "react";
import { UserDoc } from "../components/Management/Users/User";

export const defaultState = { users: undefined };

type ActionBase = {
  type: "SET";
};
type Action = ActionSet;

interface ActionSet extends ActionBase {
  type: "SET";
  users: UserDoc[] | undefined;
}

export const accessManagementReducer: Reducer<
  { users: UserDoc[] | undefined },
  Action
> = (state, action) => {
  if (action.type === "SET") {
    return { users: action.users };
  }

  return defaultState;
};
