import { useContext, useEffect, useState } from "react";
import AccessManagementContext from "../../../store/access-management-context";
import { UserDoc } from "./User";
import { ColDef, FirstDataRenderedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Chip, TextFilter } from "@yunex/yds-react";
import { Edit16 } from "@yunex/yds-icons";
import NewUserFormDialog from "../FormDialogs/NewUserFormDialog";

const UserList = () => {
  const context = useContext(AccessManagementContext);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [rowData, setRowData] = useState<UserDoc[] | undefined>(undefined);
  const [selectedUser, setSelectedUser] = useState<UserDoc | undefined>(
    undefined
  );

  useEffect(() => {
    setRowData(context.users.value);
    console.log(context.users.value);
  }, [context.users.value]);

  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    params.api?.sizeColumnsToFit();
  };

  const handleEdit = (user: UserDoc) => {
    setSelectedUser(user);
    setEditUserDialogOpen(true);
  };
  const ContextActionsRenderer = (user: UserDoc) => {
    return (
      <>
        <Button
          aria-label="button-edit"
          icon
          color="tertiary"
          onClick={() => handleEdit(user)}
        >
          <Edit16 />
        </Button>
      </>
    );
  };
  const ContextChipRenderer = (user: UserDoc) => {
    const priorityFromDate = formatDate(user.priority_production_from);
    const priorityTillDate = formatDate(user.priority_production_till);
    const toDay = new Date();
    return (
      <>
        {user.enabled === false && (
          <Chip
            color="blue"
            size="small"
            label="Inactief"
            placeholder={undefined}
          />
        )}
        {user.emailVerified === false && (
          <Chip
            color="yellow"
            size="small"
            label="Email niet geverifieerd"
            placeholder={undefined}
          />
        )}
        {user.priority_production &&
          priorityFromDate &&
          toDay >= priorityFromDate &&
          priorityTillDate &&
          toDay < priorityTillDate && (
            <Chip
              color="green"
              size="small"
              label="Priority active"
              placeholder={undefined}
            />
          )}
      </>
    );
  };

  const formatDate = (date?: string) => {
    if (date === undefined) return;
    const dateParts = date.split("-");

    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
  };

  const [defaultColDef] = useState<ColDef>({
    filter: TextFilter,
    resizable: true,
    sortable: true,
  });

  const [columnDefs] = useState<ColDef<any>[]>([
    {
      field: "firstName",
      filter: "agTextColumnFilter",
    },
    {
      field: "lastName",
      filter: "agTextColumnFilter",
    },
    {
      field: "attributes.companyName",
      headerName: "Company name",
      filter: "agTextColumnFilter",
    },
    {
      field: "email",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "",
      cellRenderer: (props: any) => {
        return ContextChipRenderer(props.data);
      },
      filter: false,
      resizable: true,
      sortable: false,
    },
    {
      headerName: "Priority from",
      field: "priority_production_from",
      filter: "agDateColumnFilter",
      maxWidth: 150,
    },
    {
      headerName: "Priority till",
      field: "priority_production_till",
      filter: "agDateColumnFilter",
      maxWidth: 150,
    },
    {
      headerName: "",
      cellRenderer: (props: any) => {
        return ContextActionsRenderer(props.data);
      },
      maxWidth: 60,
      filter: false,
      resizable: false,
      sortable: false,
    },
  ]);

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{
          height: "60vh",
          width: "100%",
        }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onFirstDataRendered={onFirstDataRendered}
          rowData={rowData}
        />
      </div>

      {selectedUser && (
        <NewUserFormDialog
          newUserDialogOpen={editUserDialogOpen}
          setNewUserDialogOpen={setEditUserDialogOpen}
          user={selectedUser}
        />
      )}
    </>
  );
};

export default UserList;
