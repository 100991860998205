export const fetchUsers = async () => {
  const response = await fetch("/api/user");
  if (!response.ok) return;
  let parsedResponse = await response.json();
  let users = parsedResponse.users;
  return users.sort((a: any, b: any) => {
    const nameA = a.email.toUpperCase();
    const nameB = b.email.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
