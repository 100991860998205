import { useContext } from "react";
import { NavLink, Outlet } from "react-router-dom";
import {
  ListItemIcon,
  SideNavItem,
  SideNavText,
  Alert,
  Snackbar,
  Typography,
} from "@yunex/yds-react";
import { User16, UserGroup16 } from "@yunex/yds-icons";

import AccessManagementContext from "../store/access-management-context";
import ContentTitle from "../components/Layout/ContentTitle";
import ContentWindow from "../components/Layout/ContentWindow";
import SideBar from "../components/Layout/SideBar";

const AccessManagementPage = () => {
  const context = useContext(AccessManagementContext);

  const handleCloseSnackbar = () => {
    context.snackbar.set({ message: "", open: false });
  };

  type NavItem = {
    text: string;
    icon: JSX.Element;
    link: string;
  };
  const navItems: NavItem[] = [
    {
      text: "Users",
      icon: <User16 />,
      link: "/access-management/users",
    },
    {
      text: "Groups",
      icon: <UserGroup16 />,
      link: "/access-management/groups",
    },
  ];

  const navElements = navItems.map((item) => {
    return (
      <NavLink
        key={item.text}
        to={item.link}
        style={{ textDecoration: "none" }}
      >
        {({ isActive }) => (
          <SideNavItem
            sx={{ marginTop: "1em" }}
            key={item.text}
            selected={isActive}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <SideNavText primary={item.text} />
          </SideNavItem>
        )}
      </NavLink>
    );
  });

  return (
    <>
      <ContentTitle>
        <h2>Access management</h2>
      </ContentTitle>
      <ContentWindow>
        <SideBar>{navElements}</SideBar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: "2em",
            padding: "2em",
          }}
        >
          <Outlet />
          {context.snackbar.value && (
            <Snackbar
              autoHideDuration={10000}
              open={context.snackbar.value.open}
              onClose={handleCloseSnackbar}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Alert severity="error" variant="filled" placeholder={"text"}>
                <Typography variant="body1">
                  {context.snackbar.value.message}
                </Typography>
              </Alert>
            </Snackbar>
          )}
        </div>
      </ContentWindow>
    </>
  );
};

export default AccessManagementPage;
