import { useState, useEffect, PropsWithChildren, useReducer } from "react";
import { AccessManagementContextType } from "./access-management-context";
import { RoleRepresentation } from "../components/Management/Users/User";
import AccessManagementContext from "./access-management-context";
import { GroupBody } from "../components/Management/Groups/Group";
import {
  accessManagementReducer,
  defaultState,
} from "./reducer-access-management";
import { fetchUsers } from "../util/helpers";

const AccessManagementProvider: React.FC<PropsWithChildren> = (props) => {
  const [users, dispatchUsersAction] = useReducer(
    accessManagementReducer,
    defaultState
  );
  const [groups, setGroups] = useState<GroupBody[] | undefined>();
  const [roles, setRoles] = useState<RoleRepresentation[] | undefined>();
  const [snackbar, setSnackbar] = useState<{
    message: string;
    open: boolean;
  }>({ message: "", open: false });

  useEffect(() => {
    fetchUsers().then((users) => {
      dispatchUsersAction({ type: "SET", users: users });
    });
    fetchGroups();
    fetchRoles();
  }, []);

  const fetchGroups = async () => {
    const response = await fetch("/api/group");
    if (!response.ok) return;
    let parsedResponse = await response.json();
    let groups = parsedResponse.groups;
    setGroups(
      groups.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    );
  };

  const fetchRoles = async () => {
    const response = await fetch("/api/role");
    if (!response.ok) return;
    let parsedResponse = await response.json();
    let roles: RoleRepresentation[] = parsedResponse.roles;
    setRoles(
      roles.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    );
  };

  const accessManagementContext: AccessManagementContextType = {
    users: {
      value: users.users,
      set: (val) => dispatchUsersAction({ type: "SET", users: val }),
    },
    groups: { value: groups, set: fetchGroups },
    roles: { value: roles, set: fetchRoles },
    snackbar: { value: snackbar, set: setSnackbar },
  };

  return (
    <AccessManagementContext.Provider value={accessManagementContext}>
      {props.children}
    </AccessManagementContext.Provider>
  );
};

export default AccessManagementProvider;
